<template>
  <!--  <v-dialog v-model="dialog" max-width="900">-->
  <!--    &lt;!&ndash; <template v-slot:activator="{ on, attrs }">-->
  <!--      <v-btn color="primary" dark v-bind="attrs" v-on="on">-->
  <!--        Open Dialog-->
  <!--      </v-btn>-->
  <!--    </template> &ndash;&gt;-->
  <!--    <v-card>-->
  <!--      <v-card-title class="primary headline white&#45;&#45;text">-->
  <!--        Generate Report-->
  <!--      </v-card-title>-->
  <!--      <v-card-text class="pa-8">-->
  <!--        <v-autocomplete-->
  <!--          v-model="selectedItem"-->
  <!--          :items="customers"-->
  <!--          item-text="text"-->
  <!--          item-value="index"-->
  <!--        ></v-autocomplete>-->
  <!--        <v-menu-->
  <!--          ref="menu"-->
  <!--          v-model="menu"-->
  <!--          :close-on-content-click="false"-->
  <!--          :return-value.sync="date"-->
  <!--          transition="scale-transition"-->
  <!--          offset-y-->
  <!--          min-width="auto"-->
  <!--        >-->
  <!--          <template v-slot:activator="{ on, attrs }">-->
  <!--            <v-text-field-->
  <!--              v-model="date"-->
  <!--              label="Picker in menu"-->
  <!--              prepend-icon="mdi-calendar"-->
  <!--              readonly-->
  <!--              v-bind="attrs"-->
  <!--              v-on="on"-->
  <!--            ></v-text-field>-->
  <!--          </template>-->
  <!--          <v-date-picker v-model="date" range no-title scrollable>-->
  <!--            <v-spacer></v-spacer>-->
  <!--            <v-btn text color="" @click="menu = false">-->
  <!--              Cancel-->
  <!--            </v-btn>-->
  <!--            <v-btn text color="primary" @click="$refs.menu.save(date)">-->
  <!--              OK-->
  <!--            </v-btn>-->
  <!--          </v-date-picker>-->
  <!--        </v-menu>-->
  <!--      </v-card-text>-->
  <!--      <v-card-actions class="d-flex justify-content-center">-->
  <!--        <v-btn @click="generator" color="primary">Generate</v-btn>-->
  <!--        <v-btn @click="dialog = false" color="danger">Close</v-btn>-->
  <!--      </v-card-actions>-->
  <!--    </v-card>-->
  <!--  </v-dialog>-->
  <div></div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  name: "Generate",
  props: ["items"],
  data: () => ({
    // date: [],
    // selectedItem: null,
    // dialog: false,
    // menu: false
  }),
  methods: {
    generator() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { filters: this.filters };
      ApiService.post("/cod/cod/print", data)
        .then((data) => {
          const link = document.createElement("a");
          link.href = data.data.url;
          link.download = "codPrint.csv";
          link.click();
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetData() {
      this.date = [];
      this.selectedItem = null;
      this.menu = false;
    },
    validateFilters() {
      // date_range
      // client
      // if(this.filters)
      let state = false;
      this.filters.map((filter) => {
        if (filter.name == "client") {
          state = true;
        }
      });
      if (state) {
        state = false;
        this.filters.map((filter) => {
          if (filter.name == "date_range.date") {
            state = true;
          }
        });
        if (!state) {
          // state = false;
          Swal.fire({
            title: "Warning",
            text: `Date range filter is required!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: `Client filter is required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
      return state;
    },
    toggleModal() {
      // this.dialog = !this.dialog;
      let isValid = this.validateFilters();
      if (isValid) {
        this.generator();
      }
    },
  },
  computed: {
    customers: function () {
      let a = this.$store.getters.getCODTableFilters.filter((filter) => {
        // console.log(filter);
        if (filter.name === "client") {
          return filter;
        }
      });
      //   console.log("a", a[0].values);
      return a[0].values;
    },
    filters: function () {
      return this.$store.getters.getCODTableState.filters;
    },
  },
};
</script>
